<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useCart } from "../../composables/useCart";
import { useOnClickOutside } from "../../composables/useOnClickOutside";
import XMarkIcon from "../Icons/XMarkIcon.vue";
const { isAddCartPopupOpen } = useCart();

const modalContainer = ref(null)
onMounted(() => {
    useOnClickOutside(modalContainer, () => {
		isAddCartPopupOpen.value = false
	});
})

interface Props {
    title?: string;
}
defineProps<Props>();
</script>

<template>
    <section class="graphi-modal" >
        <div class="container" ref="modalContainer">
            <header class="container__header">
                <h3 v-if="title" class="container__title">{{ title }}</h3>
                <XMarkIcon class="container__icon" @click="isAddCartPopupOpen = false"/>
            </header>
            <div class="container__content">
                <slot/>
            </div>
        </div>
    </section>
</template>

<style>
.graphi-modal {
  position: fixed;
  inset: 0;
  z-index: 999;
  display: grid;
  grid-template-areas: "stack";
  background-color: rgba(0, 0, 0, 0.3);
  place-items: center;
}

.graphi-modal .container {
  grid-area: stack;
  background-color: white;
  position: relative;
  border: 1px var(--default-border-color) solid;
  border-radius: 0.5rem;
}
.graphi-modal .container__icon {
  margin-left: auto;
  display: block;
  --icon-size: 15px;
  width: var(--icon-size);
  min-width: var(--icon-size);
  height: var(--icon-size);
  min-height: var(--icon-size);
  cursor: pointer;
  color: var(--neutral-color-45);
  transition: color 50ms linear;
}
.graphi-modal .container__icon:hover {
  color: initial;
}
.graphi-modal .container__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px var(--default-border-color) solid;
}
.graphi-modal .container__title {
  margin: 0;
  color: var(--mainColor);
}
.graphi-modal .container__content {
  padding: 1rem 2rem 2rem 2rem;
}
</style>