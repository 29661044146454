
<script setup lang="ts">
import type { Group } from '~/types/shop';
const userStore = useUserStore();
const { version } = useAppConfig();
const { config, links } = useConfigStore()
const { width } = useWindowSize()
const showFooter = ref(false)
const { data } = await useFetch("/api/users/getUser", { query: { details: true }})

userStore.user = data.value

const { initializeCart } = useCart()

if(!userStore.user && !userStore.user?.customerEmail) await navigateTo(links.login);
await initializeCart(userStore.user.customerEmail, config?.establishment.entityId, config?.establishment.id, config?.establishment.htSite, config?.establishment.configuration.isConsigneIncluded);

onMounted(() => {
  showFooter.value = true
})

if(config?.establishment.configuration.isShop) {
  const { data: productCat } = await useFetch<Group[]>('/api/shop/groups', { default: () => [] });
  const { setGroups } = useShopStore()  
  setGroups(productCat.value)
}

</script>

<template>
  <UDashboardLayout class="flex overflow-hidden fixed inset-0">
    <Navbar/>
    <div class="flex flex-col flex-1 w-full"> 

      <main class="flex flex-col flex-1 overflow-hidden">
        <MobileHeader v-if="width <= 1000"/>
        <NavHorizontale v-if="width > 1000"/>
  
          <slot name="default"/>
        
      </main>    
      <footer class="container-page layoutFooter text-xs" v-if="showFooter">
        <div class="flex items-center gap-1 justify-between flex-1">
          <div class="flex gap-1 items-center">
            <ElementsIcon class="picto-tecrmi" icon="tecdoc-inside"/>
            <ElementsIcon class="picto-tecrmi" icon="tecrmi-inside"/>
            <NuxtLink class="legalLink" :to="links.legalMentions">Mentions légales</NuxtLink>
          </div>
          <p class="m-0">Powered by <NuxtLink class="legalLink" :to="links.autopartspro" target="_blank">©Autopartspro</NuxtLink> {{ new Date(Date.now()).getFullYear() }} - {{ version }}</p>
        </div>
        <p class="extraSmallText hidden md:block">Les données affichées ici, particulièrement la base de donnée complète, ne doivent pas être copiées. Il est interdit d’exploiter les données ou la base de données complète, de laisser un tiers les exploiter, ni de les rendre accessible à un tiers, sans accord préalable de TecAlliance. Toute infraction constitue une violation des droits d’auteur et fera l’objet de poursuites.</p>
      </footer>  
    </div>
    <BoxAddCartPopup class="cart-popup" :link="links.cart"/>
    <ModalsVehicleSearch/>
    <ModalsVehiclesSelect/>
    <ModalsTecRmiCgu/>
  </UDashboardLayout>
</template>

<style scoped>
.layout {
  flex:1;
  display: flex;
  flex-direction: column;
}
.picto-tecrmi {
  width: 6rem;
  max-width: 6rem;
  height: 1.5rem;
  max-height: 1.5rem;
  color: var(--neutralColor2);
}
.layoutFooter {
  color: var(--neutralColor3);
  background-color: transparent;
  font-family: var(--fontFamily);
  padding: 0;
  margin-top: 0;
  border-radius: 0;
}
.legalLink {
  text-decoration: none;
  color: var(--neutralColor3);
  white-space: nowrap;
}
.extraSmallText {
  font-size: .5rem;
  margin: 0;
  line-height: .5rem;
}

@media all and (max-width: 768px) {
  .layoutFooter > div {
    display: flex;
    flex-direction: column;
    gap:.2rem;
  }
}
</style>