<script setup lang="ts">
import type { ComponentPublicInstance } from 'vue'
interface Props {
    sprite?: string;
    label: string;
    isOpen: boolean;
}
const props = defineProps<Props>();
const emits = defineEmits(["click"]);

const dropdownlist = ref<ComponentPublicInstance | null>(null);

const triggerIcon = computed(() => {
    return props.isOpen ? "minus-sharp" : "plus-sharp"
})
onMounted(() => {
    useOnClickOutside(dropdownlist, () => {
        emits('click', false);
    })
})
</script>

<template>
    <div class="dropDownButtonList" ref="dropdownlist">
        <button class="dropDownButtonList_button" @click="$emit('click', !isOpen)" :class="{unroundedBottomLeft: isOpen}"> 
            <ElementsIcon v-if="sprite" class="dropDownButtonList_icon" :icon="sprite"/>
            <span class="labelList">{{ label }}</span>
            <ElementsIcon class="dropDownButtonList_icon triggerIcon" :icon="triggerIcon"/>            
        </button>
        <div class="dropdownlistButton" :class="{openList: isOpen}" >
            <slot/>
        </div>
    </div>

</template>


<style lang="scss">
.dropDownButtonList {
    position: relative;
    width: fit-content;
    &_button {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: .5rem .6rem;
        color: var(--buttonFontColor);
        border: none;
        border-bottom-left-radius: var(--roundedBorder);
        border-top-left-radius: var(--roundedBorder);
        font: var(--fontFamily);
        font-size: .9rem;
        font-weight: 600;
        min-height: 40px;
        background-color: var(--primaryColor1);
        transition: border-radius var(--animation-site-duration) linear;
        &.unroundedBottomLeft {
            border-bottom-left-radius: 0;
        }
    }
    &_icon {
        --icon-size: 20px;
        width: var(--icon-size);
        min-width: var(--icon-size);
        height: var(--icon-size);
        min-height: var(--icon-size);
        &.triggerIcon {
            --icon-size: 20px;
           
        }        
    }
}
.labelList {
    white-space: nowrap;
}
.dropdownlistButton {
    background-color: white;
    min-width: 100%;
    width: fit-content;
    max-height: 0;
    position: absolute;
    z-index: 9999;
    overflow: hidden;
    transition: all var(--animation-site-duration) linear;
    &.openList {
        max-height: 500px;
    }
}

@media all and (max-width: 768px) {
    .dropDownButtonList {
        width: auto;
        &_button {
            width: 100%;
            border-radius: var(--roundedBorder);
            justify-content: space-between;
        }
    }
}
</style>