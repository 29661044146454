<script setup lang="ts">
import CartIcon from '../Icons/CartIcon.vue';
import { useCart } from "../../composables/useCart";

const { numberOfItems, cart } = useCart();

interface CartButtonProps {
    link: string
}

defineProps<CartButtonProps>();
</script>

<template>
    <NuxtLink :to="link" class="buttonCart" v-if="cart">
        <CartIcon class="buttonCart__icon"/>
        <span class="buttonCart__number" v-if="cart && numberOfItems > 0">{{ numberOfItems }}</span>
    </NuxtLink>
</template>

<style>
.buttonCart {
  color: initial;
  text-decoration: none;
  position: relative;
  display: block;
  width: fit-content;
}
.buttonCart__icon {
  --icon-size: 25px;
  width: var(--icon-size);
  height: var(--icon-size);
  min-width: var(--icon-size);
  min-height: var(--icon-size);
}
.buttonCart__number {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(60%, -60%);
  background-color: var(--mainColor);
  padding: 0.1rem;
  border-radius: 50%;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  min-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.8rem;
}
</style>