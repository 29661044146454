<script
    lang="ts"
    setup
>
import SearchBar from '~/components/Elements/SearchBar.vue';
import Modal from "@/components/Elements/Modal.vue";
import ModalContact from "@/components/modals/ModalsContact.vue";

const userStore = useUserStore()
const {toFixedComma} = useFormatNumber();
const {isSearchVehicleOpen} = useDashboard();
const {currentDevise} = useCart();
const {config: appConfig, links} = useConfigStore()
const {hasGenericInformations} = useVehicle()
const isAnimated = ref<boolean>(false);
const {isDevis} = useLicenceStore();
const {calculateTotalWorkHt, calculateTotalPartsHt, calculateTotalDiversHt, quote} = useQuote()

const quoteUrl = computed(() => !isDevis(appConfig?.establishment.configuration.isDevis) ? '' : quote.value?.id ? `${ links.devisEdit }${ quote.value.id }?refresh=false` : links.devis);
const navigateToQuote = () => {
    if(!hasGenericInformations.value) {
        isSearchVehicleOpen.value = true
        return
    }

    return navigateTo(quoteUrl.value)
}
const worksTotalAfterPromoHt = computed(() => calculateTotalWorkHt(quote.value.articles, true));
const partsTotalAfterPromoHt = computed(() => calculateTotalPartsHt(quote.value.articles, true));
const diversTotalAfterPromoHt = computed(() => calculateTotalDiversHt(quote.value.articles, true));

const totalHt = computed(() => worksTotalAfterPromoHt.value + partsTotalAfterPromoHt.value + diversTotalAfterPromoHt.value);

watch(totalHt, () => {
    isAnimated.value = true;
    setTimeout(() => {
        isAnimated.value = false
    }, 1000);
});

const isContactOpen = ref(false);

defineEmits(['close']);

const items = [[{
    label: 'Mon abonnement',
    to: links.accountSubscription
}, {
    label:'Mes mécaniciens',
    disabled: true
}, {
    label:'Mes prestations',
    disabled: true
}, {
    label: 'Mes taux horaires',
    to: links.accountRate
}, {
    label: 'Mes paramètres',
    to: links.accountCustom
}]]

</script>

<template>
    <section :class="['p-0', 'container-page', 'flex', 'bg-transparent', 'horizontalNav', 'gap-3.5']">
        <div class="box-input w-full">
            <SearchBar/>
        </div>

        <div class="box-icon">
            <BoxCartButton
                :class="['btnCart', { disabled: !appConfig?.establishment.configuration.isCart }]"
                :link="appConfig?.establishment.configuration.isCart ? links.cart : ''"
            />

            <NuxtLink
                class="icon-devis"
                :aria-disabled="!isDevis(appConfig?.establishment.configuration.isDevis)"
                :class="{ isDisabled: !isDevis(appConfig?.establishment.configuration.isDevis) }"
                @click="navigateToQuote"
            >
                <span
                    class="pastille"
                    v-if="quote?.articles?.length"
                    :class="{ animated: isAnimated }"
                >
                    {{ toFixedComma(totalHt, 2) }} {{ currentDevise }}
                </span>
                <ElementsIcon icon="calculator"/>
            </NuxtLink>

            <a>
                <ElementsIcon icon="notifications"/>
            </a>

            <NuxtLink :to="links.account">
                <ElementsIcon icon="user"/>
            </NuxtLink>

            <UDropdown :items="items">
                <ElementsIcon icon="fa-regular-gear"/>

            </UDropdown>
<!--            <div-->
<!--                class="dropdown-menu dropdown-menu-end"-->
<!--                aria-labelledby="dropdownMenuLink"-->
<!--            >-->
<!--                <p class="main-color d-flex justify-content-between align-items-center column-gap-5">-->
<!--                    <span>Mes paramètres</span>-->
<!--                    <ElementsIcon icon="xmark"/>-->
<!--                </p>-->
<!--                <hr>-->
<!--                <NuxtLink-->
<!--                    class="dropdown-item"-->
<!--                    :to="links.accountSubscription"-->
<!--                >Mon abonnement</NuxtLink>-->
<!--                <NuxtLink class="isDisabled dropdown-item">Mes mécaniciens</NuxtLink>-->
<!--                <NuxtLink class="isDisabled dropdown-item">Mes prestations</NuxtLink>-->
<!--                <NuxtLink-->
<!--                    class="dropdown-item"-->
<!--                    :to="links.accountRate"-->
<!--                >Mes taux horaires</NuxtLink>-->
<!--                <NuxtLink-->
<!--                    class="dropdown-item"-->
<!--                    :to="links.accountCustom"-->
<!--                >Mes paramètres</NuxtLink>-->
<!--            </div>-->


            <NuxtLink
                v-if="appConfig?.establishment.email != null || appConfig?.establishment.phone != null || (userStore.user.succursalle && userStore.user.succursalle.phone)"
                to=""
                @click.prevent="isContactOpen = true"
            >
                <ElementsIcon icon="fa-regular-phone"/>
            </NuxtLink>

            <Modal
                :isOpen="isContactOpen"
                @close="isContactOpen = false"
            >
                <template #content>
                    <ModalContact/>
                </template>
            </Modal>

        </div>
    </section>
</template>

<style
    lang="scss"
    scoped
>
.horizontalNav {
    padding: 0;

    & .iconDefault {
        --icon-size-default: 25px;
        color: var(--secondaryColor1);
    }

}

.box-icon {
    background: var(--secondary-background-color);
    flex: 1;
    padding: 1rem 1.25rem;
    border-radius: var(--roundedBorder);
    display: flex;
    align-items: center;
    gap: 2rem;
    min-height: 70px;
}

.box-icon a svg {

    transition: 0.7s all;
}

.box-icon a:hover svg {
    transform: translateY(-5px);
}

.dropdown-menu.show {
    transform: translate(-104px, 76px) !important;
    padding: 1.25rem 1.5rem;
}

.dropdown-item {
    font-family: Inter;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.btnCart:deep(.buttonCart__number) {
    font-size: .825rem;
    transform: translate(34%, -20%);
    background-color: var(--primaryColor1);
    color: var(--buttonFontColor);
}

.btnCart.disabled:deep(svg) {
    color: var(--neutralColor3);
}

.icon-devis {
    position: relative;
    cursor: pointer;

}

.icon-devis .pastille {
    position: absolute;
    top: -20%;
    right: -20%;
    transform: translate(70%, 0%);
    background-color: red;
    padding: 0.1rem;
    border-radius: 7%;
    min-width: 1rem;
    height: 0rem;
    min-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 0.8rem;
    z-index: 1;
    white-space: nowrap;
}

@media screen and (max-width: 1200px) {
    .horizontalNav {
        flex-direction: column-reverse;
    }

    .box-icon {
        justify-content: space-between;
    }
}

@media screen and (max-width: 1000px) {
    .horizontalNav {
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }


    .box-icon {
        justify-content: center;

    }
}

.animated {
    animation: 1s infinite scaleUp;
}

@keyframes scaleUp {
    0% {
        transform: translate(70%, 0%) scale(1);
    }

    50% {
        transform: translate(70%, 0%) scale(2);

    }

    100% {
        transform: translate(70%, 0%) scale(1);
    }
}
</style>
