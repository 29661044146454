<script setup lang="ts">
import Modal from "../Modals/Modal.vue";
import RoundedButton from "../Buttons/RoundedButton.vue";
import { useCart } from "../../composables/useCart";
import CartIcon from "../Icons/CartIcon.vue";
import SolidArrowLeftIcon from "../Icons/SolidArrowLeftIcon.vue";
interface Props {
    link: string;
}
defineProps<Props>();

const { isAddCartPopupOpen } = useCart();
</script>

<template>
    <Modal v-if="isAddCartPopupOpen" class="addCartPopup">
        <span>Ajouté au panier !</span>
        <RoundedButton :light="true" :bordered="true" @click="isAddCartPopupOpen = false" class="fullWidth"> 
            <SolidArrowLeftIcon class="addCartPopup__icon"/>
            Continuer mes achats
        </RoundedButton>        
        <RoundedButton :link="link" @click="isAddCartPopupOpen = false" class="fullWidth" >
            <CartIcon class="addCartPopup__icon"/> 
            Accéder au panier
        </RoundedButton>
    </Modal>
</template>

<style>
.addCartPopup .container {
  max-width: 350px;
}
.addCartPopup .container__content {
  display: grid;
  gap: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.addCartPopup .container span {
  text-align: center;
}

.roundedButton.fullWidth {
  box-sizing: border-box;
  width: 100%;
}

svg.addCartPopup__icon {
  --icon-size: 20px;
  width: var(--icon-size);
  height: var(--icon-size);
  min-height: var(--icon-size);
  min-width: var(--icon-size);
}
</style>