<script
    lang="ts"
    setup
>
import ButtonDropDownList from "@/components/Elements/ButtonDropDownList.vue";
import type { SearchType } from "~/types/interfaces";
import { searchTypesId } from "~/types/datas";

const {links, config} = useConfigStore()
const searchTypes = ref<SearchType[]>([{
    id: searchTypesId.anyNumber,
    label: "Recherche globale <span>(par défaut)</span>",
    sprite: "globe"
}, {
    id: searchTypesId.oeNumber,
    label: "Recherche par numéro OE",
    sprite: "arrow-left-right"
}, {
    id: searchTypesId.comparableNumber,
    label: "Recherche par référence concurrente",
    sprite: "list-number"
}, {
    id: searchTypesId.articleNumber,
    label: "Recherche par numéro d'article",
    sprite: "hashtag"
}, {
    id: searchTypesId.tradeNumber,
    label: "Recherche par référence commerciale",
    sprite: "fa-regular-building"
}, {
    id: searchTypesId.shop,
    label: "Recherche dans la boutique",
    sprite: "shop",
    disabled: !config?.establishment.configuration.isShop
}]);

const formId = useId();
const userInput = ref<string>("");
const route = useRoute()
const defaultSelectedType = ref(searchTypesId.anyNumber);
const selectedSearchTypeId = ref<number>(defaultSelectedType.value);
const listOpen = ref<boolean>(false);

//@ts-ignore
const searchTypeSelected = computed((): SearchType => searchTypes.value.filter(type => type.id === selectedSearchTypeId.value)[0]);

const searchByOEM = () => {
    if (selectedSearchTypeId.value !== searchTypesId.shop) {
        return navigateTo(`${ links.productList }?search=${ userInput.value }&searchType=${ selectedSearchTypeId.value }`)
    }

    return navigateTo(`${ links.shopSearch }?search=${ userInput.value }`)
};

function setDefaultSelectedTypeId() {
    if (route.path.includes(links.shop)) {
        selectedSearchTypeId.value = searchTypesId.shop
        return
    }
    selectedSearchTypeId.value = searchTypesId.anyNumber
}

onBeforeMount(() => setDefaultSelectedTypeId())
watch(() => route.path, () => {
    setDefaultSelectedTypeId()
    userInput.value = ""
})
</script>
<template>
    <div class="searchBar">
        <ButtonDropDownList
            class="searchBar-dropdown"
            :isOpen="listOpen"
            :label="searchTypeSelected.id !== searchTypesId.anyNumber ? searchTypeSelected.label : 'Recherche globale'"
            :sprite="searchTypeSelected.sprite"
            @click="listOpen = $event"
        >
            <ul class="no-list-style">
                <template
                    v-for="(searchType, index) in searchTypes"
                    :key="`${searchType.id}${index}`"
                >
                    <li v-if="!searchType?.disabled">
                        <button
                            class="searchBar_buttonType"
                            @click="selectedSearchTypeId = searchType.id; listOpen = !listOpen"
                        >
                            <ElementsIcon :icon="searchType.sprite"/>
                            <span
                                class="label"
                                v-html="searchType.label"
                            ></span>
                        </button>
                    </li>
                </template>
            </ul>
        </ButtonDropDownList>    
        <label
            class="searchBar_search"
            :id="`searchBar_${formId}`"
        >
            <input
                class="searchBar_input flex-1"
                v-model="userInput"
                aria-label="Rechercher par référence"
                placeholder="Recherche par référence..."
                type="text"
                @click="listOpen = false"
                @keydown.enter="searchByOEM"
            />
            <ElementsButtonLinear
                class="buttonSearchBar"
                preIcon="fa-solid-magnifying-glass"
                @click="searchByOEM"
            />
        </label>
    </div>           
</template>

<style lang="scss">
.searchBar {
    width: 100%;
    position: relative;
    display: flex;

    & ul {
        margin: 0;
        padding: 1rem;
        border: 1px var(--primaryColor1) solid;

        & li {
            line-height: 2rem;
        }
    }

    &_search {
        display: flex;
        gap: 1rem;
        align-items: center;
        min-width: 25%;
    }

    &_buttonType {
        border: none;
        display: flex;
        align-items: center;
        gap: 1rem;
        background-color: transparent;
        transition: color var(--animation-site-duration) linear;

        &:hover {
            color: var(--primaryColor1);
        }

        & svg {
            --icon-size: 20px;
            width: var(--icon-size);
            min-width: var(--icon-size);
            height: var(--icon-size);
            min-height: var(--icon-size);
            color: var(--primaryColor1);
        }

        & .label {
            white-space: nowrap;

            & span {
                color: var(--neutralColor3);
            }
        }
    }
}

.buttonSearchBar {
    min-height: 40px;
}

.searchBar_input {
    border-radius: 0 var(--roundedBorder) var(--roundedBorder) 0;
    border: 1px var(--primaryColor1) solid;
    border-left: none;
    height: 40px;
    padding: .5rem;

    &:focus {
        border-color: var(--primaryColor1) !important;
        box-shadow: 0 0 4px var(--primaryColor1) !important;
        outline: none;
    }

    &:focus-visible {
        border-color: var(--primaryColor1) !important;
        box-shadow: 0 0 4px var(--primaryColor1) !important;
        outline: none;
    }
}

@media all and (max-width: 1000px) {
    .searchBar {
        flex-direction: column;
        gap: .5rem;

        &_search {
            flex-direction: column;
            gap: .5rem;
        }
    }
    .searchBar_input {
        border-radius: var(--roundedBorder);
        border-left: 1px var(--primaryColor1) solid;

    }
    .buttonSearchBar {
        width: 100%;
    }
    .searchBar-dropdown {
        width: 100%;

    }
    .searchBar-dropdown button {
        width: 100%;
        border-radius: var(--roundedBorder);
        justify-content: space-between;
    }
}
</style>