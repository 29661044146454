<script setup lang="ts">
import type { VehicleResponse } from '~/types/vehicle';

const { links } = useConfigStore()
const userStore = useUserStore();
const { resetVehicleInformations, setVehicleInformations, vehiclesPlateDatas, vehicleTypeList } = useVehicle()
const { addToast } = useAppToast()
const { isMultipleVehicleSelectOpen, searchVehicleFrom } = useDashboard()
const { updateVehicleQuote } = useQuote()

const headerValues = ref([{
    value: 'Type',
    textAlign: 'left'
}, {
    value: 'Numéro TecDoc',
    textAlign: 'center'
}, {
    value: 'Code moteur',
    textAlign: 'center'
}, {
    value: 'Capacité',
    textAlign: 'center'
}, {
    value: 'Alimentation',
    textAlign: 'center'
}]);

const selectVehicle = async (kType: number, plateNumber?: string) => {
    try {
        const getCarByPlateResponse = await $fetch<VehicleResponse>('/api/siv/getCarByKtype', { query: {
            immat: plateNumber,
            userEmail: userStore.user.customerEmail,
            Ktype: kType
        }})

        if(getCarByPlateResponse.success !== "1") throw new Error(`[${getCarByPlateResponse.err_code}] ${getCarByPlateResponse.msg}`)
    
        const singleVehicle = getCarByPlateResponse?.carPlateDatas.length ? getCarByPlateResponse.carPlateDatas.filter((vehicle) => vehicle.KType === kType && vehicle.numberPlate === plateNumber) : []
        const genericVehicle = getCarByPlateResponse.carGenericDatas.filter((vehicle) => vehicle.KType === kType)
    
        const isFromKilometers = searchVehicleFrom.value === 'selectKilometers'
        const vin = singleVehicle.length > 0 ? singleVehicle[0].vin : ''

        resetVehicleInformations(!isFromKilometers)
        setVehicleInformations(kType, vin, plateNumber, singleVehicle[0], genericVehicle[0])

        if(!isFromKilometers) {
            await navigateTo(links.catalog)
        }

        if(isFromKilometers) updateVehicleQuote(singleVehicle[0].KType, singleVehicle[0].carName)
        isMultipleVehicleSelectOpen.value = false
        
    } catch(err: any) {
        console.error(err)
        addToast("Une erreur est survenue lors de la récupération du véhicule.", 'error')
    }
}
</script>

<template>
    <ElementsModal :isOpen="isMultipleVehicleSelectOpen" @close="isMultipleVehicleSelectOpen = false" title="Validez votre véhicule">
        <template #content>
            <ElementsSearchNumberPlate :isLabel="false" class="flex items-center flex-col justify-center mb-3.5"/>
            <BoxTable class="box-dashbord table" :isActions="true">
                <template #headerTable>
                    <BoxItemHeaderTable v-for="(headerItem, index) in headerValues" :key="index" :title="headerItem.value" :textAlign="headerItem.textAlign" />
                </template>

                <template #mainTable v-if="vehiclesPlateDatas.length">
                    <BoxItemRowTable v-for="(vehicle, index) in vehiclesPlateDatas" :key="index">
                        <BoxItemBodyTable :value="vehicle.carName" textAlign="left" class="font-medium"/>
                        <BoxItemBodyTable :value="vehicle.tecDocModNr" textAlign="center"/>
                        <BoxItemBodyTable :value="vehicle.engineCode" textAlign="center"/>
                        <BoxItemBodyTable :value="vehicle.engineCapacity" textAlign="center"/>
                        <BoxItemBodyTable :value="`${vehicle.power} Kw`" textAlign="center"/>                        
                        <BoxItemActionTable 
                            class="table-actions-primary" 
                            :isShowAction="searchVehicleFrom !== 'selectKilometers'" 
                            @show="selectVehicle(vehicle.KType, vehicle.numberPlate)" 
                            :isAddAction="searchVehicleFrom === 'selectKilometers'"
                            @add="selectVehicle(vehicle.KType, vehicle.numberPlate)" 
                        />
                    </BoxItemRowTable>
                </template>

                <template #mainTable v-if="vehicleTypeList.length">                    
                    <BoxItemRowTable v-for="(vehicle, index) in vehicleTypeList" :key="index">
                        <BoxItemBodyTable :value="vehicle.typeName" textAlign="left" class="font-medium"/>
                        <BoxItemBodyTable value="" textAlign="center"/>
                        <BoxItemBodyTable :value="vehicle.typeDetails.find(type => type.addInfoKeyId === -4)?.addInfoKeyValue" textAlign="center"/>
                        <BoxItemBodyTable :value="vehicle.typeDetails.find(type => type.addInfoKeyId === 6)?.addInfoKeyValue" textAlign="center"/>
                        <BoxItemBodyTable 
                            :value="`${vehicle.typeDetails.find(type => type.addInfoKeyId === 5)?.addInfoKeyValue} ${vehicle.typeDetails.find(type => type.addInfoKeyId === 5)?.addInfoKeyName}`" 
                            textAlign="center"
                        />                        
                        <BoxItemActionTable 
                            class="table-actions-primary" 
                            :isShowAction="searchVehicleFrom !== 'selectKilometers'" 
                            @show="selectVehicle(vehicle.tcdTypeId)" 
                            :isAddAction="searchVehicleFrom === 'selectKilometers'"
                            @add="selectVehicle(vehicle.tcdTypeId)" 
                        />
                    </BoxItemRowTable>
                </template>
            </BoxTable>
        </template>
    </ElementsModal>
</template>