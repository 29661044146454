<script setup lang="ts">
const { config, links } = useConfigStore()
const { isMobileNavBarOpen } = useDashboard()
</script>

<template>
    <section class="mobileHeader">
        <div class="flex gap-4 items-center justify-between">
            <NuxtLink 
                :to="links.dashboard" 
                :title="config?.establishment.companyName"
            >
                <NuxtImg 
                    :src="`${links.cdnUrl}/${config?.establishment.entityId}/${config?.establishment.id}/images/logo.png`" 
                    placeholder="/img/icons/img-default.png" 
                    width="100"
                    fit="cover"
                />
            </NuxtLink>
            <ElementsIcon icon="fa-regular-bars" @click="isMobileNavBarOpen = true"/>
        </div>
    </section>
</template>

<style scoped lang="scss">
.mobileHeader {   
    padding: .5rem .8rem;
    background-color: var(--secondary-background-color);
    & svg {
        --icon-size-default: 1.5rem;
        cursor: pointer;
        transition: color var(--animation-site-duration) linear;
        &:hover {
            color: var(--primaryColor1);
        }
    }
}
</style>