<script
    lang="ts"
    setup
>
const {navbarApp} = useUIApp()
const storeConfig = useConfigStore()
const {config, links} = storeToRefs(storeConfig)
const {isSearchVehicleOpen, isMobileNavBarOpen} = useDashboard()
const userStore = useUserStore();
const {hasVehicleInformations} = useVehicle()
const {width} = useWindowSize()
const router = useRouter();

const linksStates = ref(new Map<string, boolean>())
const BOUTIQUE_NAME = "Boutique";

const toggleCurrentLinkDropdown = (link: string, nameCategory: string, linkUrl: string) => {
    const isOpen = linksStates.value.get(link) || false;

    if (nameCategory === BOUTIQUE_NAME) {
        if (!isOpen) {
            linksStates.value.set(link, true);
            router.push(linkUrl);
        } else {
            linksStates.value.set(link, false);
        }
        return;
    }

    linksStates.value.set(link, !isOpen);
}
const isDropdownLinkOpen = (link: string) => {
    return linksStates.value.get(link) || false;
}

const toggleVehicleSearch = (isVehicleNeeded: boolean | undefined) => {
    if (isVehicleNeeded && !hasVehicleInformations.value) {
        isSearchVehicleOpen.value = true
        return
    }
}

</script>

<template>
    <div :class="['min-h-dvh', 'min-w-56', 'max-w-64', 'navbarApp', 'flex', 'flex-col', { mobilNavbar: width <= 1000 }, { open: isMobileNavBarOpen }]">
        <div class="headerNav">
            <NuxtLink
                class="max-w-64 headerNav-link"
                :title="config?.establishment.companyName"
                :to="links.dashboard"
            >
                <NuxtImg
                    class="max-w-40 max-h-20"
                    v-if="config"
                    :src="`${links.cdnUrl}/${config?.establishment.entityId}/${config?.establishment.id}/images/logo.png`"
                    placeholder="/img/missing-img.png"
                />
            </NuxtLink>

            <p
                class="text-xs main-color font-semibold"
                v-if="config?.establishment.configuration.showBranchName"
            >
                {{ userStore?.user?.succursalle?.succursalleName }}
            </p>

            <ElementsIcon
                v-if="width <= 1000"
                icon="xmark"
                @click="isMobileNavBarOpen = false"
            />
        </div>

        <ElementsDivider class="my-4"/>

        <nav>
            <ul class="p-0">
                <template
                    v-for="(link, linkIndex) in navbarApp"
                    :key="link.name"
                >
                    <li v-if="!link.hidden">
                        <NuxtLink
                            v-if="!link.childrens.length"
                            :class="['appLinks', 'font-semibold', 'text-sm', { 'disabled-link': link.disabled }]"
                            :target="link.target"
                            :to="link.disabled || (link.isVehicleNeeded && !hasVehicleInformations) ? '' : link.to"
                            @click="toggleVehicleSearch(link.isVehicleNeeded)"
                        >
                            <ElementsIcon :icon="link.picture"/>
                            {{ link.name }}
                        </NuxtLink>

                        <div v-else>
                            <button
                                :class="['appLinks', 'font-semibold', 'text-sm', { 'disabled-link': link.disabled }]"
                                type="button"
                                @click="toggleCurrentLinkDropdown(`${link.id}_${link.name}_${linkIndex}`, link.name, link.to)"
                            >
                                <ElementsIcon :icon="link.picture"/>
                                {{ link.name }}
                                <ElementsIcon
                                    :class="['iconSmall', 'moauto', 'angleLinks', { 'rotate-right': isDropdownLinkOpen(`${link.id}_${link.name}_${linkIndex}`) }]"
                                    icon="fa-angle-right"
                                />
                            </button>

                            <ElementsDropdown :isOpen="isDropdownLinkOpen(`${link.id}_${link.name}_${linkIndex}`)">
                                <template #content>
                                    <ul class="p0">
                                        <li
                                            v-for="subLink in link.childrens"
                                            :key="subLink.name"
                                        >
                                            <NuxtLink
                                                :class="['appLinks', 'font-semibold', 'appLinks-subLinks', 'text-sm', { 'disabled-link': subLink.disabled }]"
                                                :target="subLink.target"
                                                :to="!subLink.disabled ? subLink.to : ''"
                                            >
                                                <ElementsIcon
                                                    class="iconSmall"
                                                    icon="fa-angle-right"
                                                />
                                                {{ subLink.name }}
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </template>
                            </ElementsDropdown>
                        </div>
                    </li>
                </template>

            </ul>
        </nav>

        <div class="flex flex-1">
            <NavHorizontale v-if="width <= 1000"/>
        </div>
        <NuxtLink
            class="deconnection-button"
            :to="links.login"
            title="Déconnexion"
        >
            <ElementsIcon icon="fa-regular-circle-xmark"/> Déconnexion
        </NuxtLink>
    </div>
</template>


<style
    lang="scss"
    scoped
>
.navbarApp {
    background-color: var(--secondary-background-color);
    overflow-y: auto;
}

.mobilNavbar {
    transform: translateX(-100%);
    transition: transform var(--animation-navbar-duration) ease-in-out;

    &.open {
        transform: translateX(0);
    }
}

.headerNav {
    padding: 2.5rem 1.75rem 0 1.75rem;
    position: relative;

    &-link {
        display: block;
        margin-bottom: 0.625rem;

        &.router-link-exact-active {
            background-color: transparent;
        }
    }

    & svg {
        position: absolute;
        top: 1rem;
        right: 1rem;
        --icon-size-default: 2rem;
        cursor: pointer;
        transition: color var(--animation-site-duration) linear;

        &:hover {
            color: var(--primaryColor1);
        }
    }
}

.appLinks {
    display: flex;
    color: var(--secondaryColor1);
    text-decoration: none;
    gap: .75rem;
    align-items: center;
    padding: .5rem 1.75rem;
    border: none;
    border-left: 0.1875rem transparent solid;
    cursor: pointer;
    background-color: transparent;
    width: 100%;

    &-subLinks {
        padding: .5rem 1.75rem .5rem 2.5rem;

    }

    &.disabled-link {
        background-color: transparent;
        border-color: transparent;
        color: var(--neutralColor3);
        cursor: default;
    }

    &:hover:not(.disabled-link) {
        background-color: var(--primaryColor3);
        border-color: var(--primaryColor1);
        color: var(--primaryColor1);
    }
}

.router-link-exact-active {
    background-color: var(--primaryColor3);
    border-color: var(--primaryColor1);
    color: var(--primaryColor1);
}

.angleLinks {
    transition: transform var(--animation-site-duration) linear;
}

.rotate-right {
    transform: rotateZ(90deg);
}

.deconnection-button {
    color: var(--primaryColor1);
    border: 2px var(--primaryColor1) solid;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: .5rem;
    font-weight: 500;
    line-height: 1.5rem;
    /* 150% */
    letter-spacing: -0.01rem;
    border-radius: var(--roundedBorder);
    width: fit-content;
    padding: .625rem 1rem;
    margin: 1rem;
    transition: all var(--animation-site-duration) linear;

    &:hover {
        background-color: var(--primaryColor1);
        color: var(--buttonFontColor);
    }
}

@media all and (max-width: 1000px) {
    .navbarApp {
        max-width: 400px;
        position: fixed;
        inset: 0;
        z-index: 11000;
    }

    .deconnection-button {
        margin: 2rem auto 1rem auto;
    }
}

@media all and (max-width: 768px) {
    .mobilNavbar {
        max-width: 100%;
    }
}
</style>