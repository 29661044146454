import { createSharedComposable } from '@vueuse/core';
import { useLicenceStore } from "~/store/useLicenceStore";
import { useShopStore } from '~/store/useShopStore';

const _useUIApp = () => {
	const {isDevis} = useLicenceStore();
	const storeConfig = useConfigStore()
	const {config: appConfig, links} = storeToRefs(storeConfig)

	const shopStore = useShopStore()
	const {groups} = storeToRefs(shopStore)

	const shopSubCategories = groups.value.map((category, index) => ({
		id: index + 1,
		name: category.title,
		to: `${ links.value.shop }/${ normalizeTitle(category.title) }-${ category.id }`,
		disabled: !category.title
	}));

	const navbarApp = reactive([{
		id: 1,
		name: "Tableau de bord",
		picture: "dashboard",
		fontAwesome: "",
		to: links.value.dashboard,
		disabled: false,
		childrens: [],
		target: ''
	}, {
		id: 2,
		name: "Mon activité",
		picture: "pencil",
		fontAwesome: "",
		to: "/activite",
		childrens: [{
			id: 3,
			name: "Mes véhicules",
			to: links.value.vehicle
		}, {
			id: 4,
			name: "Mes commandes",
			to: links.value.orders
		}, {
			id: 5,
			name: "Mes devis",
			to: links.value.devisHistory,
			disabled: !isDevis(appConfig.value?.establishment.configuration.isDevis)
		}, {
			id: 6,
			name: "Mes clients",
			to: links.value.ListCustomer,
			disabled: false
		}, {
			id: 7,
			name: "Mes rendez-vous",
			to: "",
			disabled: true
		}]
	}, {
		id: 8,
		name: "Catalogue",
		picture: "catalog",
		alternativePicture: "",
		to: links.value.catalog,
		isVehicleNeeded: true,
		childrens: []
	}, {
		id: 9,
		name: "Boutique",
		picture: "shop",
		alternativePicture: "",
		to: links.value.shop,
		disabled: !appConfig.value?.establishment.configuration.isShop,
		childrens: !appConfig.value?.establishment.configuration.isShop ? [] : shopSubCategories
	}, {
		id: 10,
		name: "Pneumatique",
		picture: "tire",
		alternativePicture: "",
		to: links.value.tires,
		disabled: !appConfig.value?.establishment.configuration.isPneumatic,

		childrens: []
	}, {
		id: 11,
		name: "Gestion atelier",
		picture: "clede10",
		alternativePicture: "",
		to: "/gestion-atelier",
		disabled: true,
		childrens: [{
			id: 12,
			name: "Planning rendez-vous",
			to: "",
			disabled: true
		}, {
			id: 13,
			name: "Ordre de mission",
			to: "",
			disabled: true
		}, {
			id: 14,
			name: "Suivi heures facturées",
			to: "",
			disabled: true
		}]
	}, {
		id: 15,
		name: "Gérer mon site",
		picture: "fa-regular-home",
		alternativePicture: "",
		childrens: [{
			id: 23,
			name: "Mon site internet",
			to: appConfig.value?.establishment.siteGarageUrl,
			disabled: !appConfig.value?.establishment.siteGarageUrl,
			target: '_blank'
		}, {
			id: 16,
			name: "Actualités",
			to: "",
			disabled: true
		}, {
			id: 17,
			name: "Promotions",
			to: "",
			disabled: true

		}, {
			id: 18,
			name: "Diaporama",
			to: "",
			disabled: true

		}, {
			id: 19,
			name: "Véhicules d’occasion",
			to: " ",
			disabled: true

		}, {
			id: 20,
			name: "Recrutement",
			to: "",
			disabled: true
		}]
	}, {
		id: 21,
		name: "Statistiques",
		picture: "circle-plus",
		alternativePicture: "",
		to: "/stats",
		disabled: true,
		childrens: [{
			id: 22,
			name: "Plaques",
			to: "",
			disabled: true
		}]
	}, {
		id: 22,
		name: "Super power",
		picture: "fa-regular-wand-magic-sparkles",
		hidden: true,
		childrens: [{
			id: 27,
			name: "Configurateur",
			to: "/powers/configurator/start"
		},{
			id: 23,
			name: "Entités",
			to: "/powers/entities"
		}, {
			id: 24,
			name: "Établissements",
			to: "/powers/establishments"
		}, {
			id: 25,
			name: "Thèmes",
			to: "/powers/themes"
		}, {
			id: 26,
			name: "Fournisseurs",
			to: "/powers/suppliers"
		}, {
			id: 28,
			name: "Licences",
			to: "/powers/licences"
		}]
	}, {
		id: 22,
		name: "Administration",
		picture: "fa-regular-lock",
		hidden: true,
		childrens: []
	}])

	return {
		navbarApp
	};
}

export const useUIApp = createSharedComposable(_useUIApp)